import {
  CHATFLOW_CUSTOM_KEYS,
  ChatFlowsEnum,
  IBotFlow,
  IDashboardNode,
  KeyType
} from "@limbic/types"
import { IChatbotPropertyKey } from "@limbic/types"
import invariant from "./invariant"
import toCamelCase from "./toCamelCase"
import {
  isChatFlowNode,
  isCheckboxNode,
  isDateNode,
  isFreeTextNode,
  isInlinePickerNode,
  isSetStateActionNode
} from "./node"
import Logger from "./Logger"

export default function getChatbotPropertyKeys(flow: IBotFlow): IChatbotPropertyKey[] {
  const dialogues = Object.entries(flow ?? {}).map(([dialogue, flow]) => ({
    dialogue,
    nodes: flow.dashboard.nodes
  }))

  if (!dialogues.length) return []

  const chatbotPropertyKeys: IChatbotPropertyKey[] = []
  dialogues.forEach(({ dialogue, nodes }) => {
    nodes.forEach((node: IDashboardNode) => {
      const keys: IChatbotPropertyKey[] = []

      if (isChatFlowNode(node)) {
        const propertyKeys = getChatFlowChatbotProperties(dialogue, node)
        keys.push(...propertyKeys)
      }

      if (isCheckboxNode(node)) {
        const propertyKeys = getCheckboxChatbotProperties(dialogue, node)
        keys.push(...propertyKeys)
      }

      if (isInlinePickerNode(node)) {
        const propertyKeys = getInlinePickerChatbotProperties(dialogue, node)
        keys.push(...propertyKeys)
      }

      if (isFreeTextNode(node)) {
        const questionID = node.settings?.questionID
        const questionName = node.settings?.questionName
        const context = `${dialogue}-->${questionName ?? node.id}`
        const sourceKey = questionID ?? questionName!
        keys.push({ type: KeyType.Text, context, sourceKey })
      }

      if (isDateNode(node)) {
        const questionID = node.settings?.questionID
        const questionName = node.settings?.questionName
        const context = `${dialogue}-->${questionName ?? node.id}`
        const sourceKey = questionID ?? questionName!
        keys.push({ type: KeyType.Number, context, sourceKey })
      }

      if (isSetStateActionNode(node)) {
        const propertyKeys = getSetStateChatbotProperties(dialogue, node)
        keys.push(...propertyKeys)
      }

      chatbotPropertyKeys.push(...keys)
    })
  })

  return chatbotPropertyKeys
}

function getChatFlowChatbotProperties(
  dialogue: string,
  node: IDashboardNode
): IChatbotPropertyKey[] {
  const chatFlow = node.settings?.chatFlow
  invariant<ChatFlowsEnum>(chatFlow, `Chat flow ${chatFlow} not found for node ${node.id}`)

  const context = `${dialogue}-->${node.id}-${chatFlow}`
  const settings = node.settings?.chatFlowSettings?.[chatFlow!] ?? {}

  return Object.entries(CHATFLOW_CUSTOM_KEYS[chatFlow!] ?? {}) //
    .map(([key, metaData]) => {
      const values = [...(metaData.allowedValues ?? [])]
      const sourceKey = settings[key] ?? metaData.defaultKey
      return { type: metaData.type, context, sourceKey, values }
    })
}

function getCheckboxChatbotProperties(
  dialogue: string,
  node: IDashboardNode
): IChatbotPropertyKey[] {
  const questionName = node.settings?.questionName
  const context = `${dialogue}-->${questionName ?? node.id}`

  const checkboxOptions = node.settings?.promptSettings?.checkboxOptions ?? []
  return checkboxOptions.map(o => ({
    type: KeyType.Boolean,
    context,
    sourceKey: o.body.includes(" ") ? toCamelCase(o.body) : o.body,
    values: [true, false]
  }))
}

function getInlinePickerChatbotProperties(
  dialogue: string,
  node: IDashboardNode
): IChatbotPropertyKey[] {
  const promptType = node.settings?.promptType
  const questionID = node.settings?.questionID
  const questionName = node.settings?.questionName
  const context = `${dialogue}-->${questionName ?? node.id}`
  const sourceKey = questionID ?? questionName!
  const keyType = promptType === "inlinePickerMultiSelect" ? KeyType.TextList : KeyType.Text
  const values = node.settings?.promptSettings?.options ?? []

  if (node.settings?.promptSettings?.textPromptWithInlineOption) {
    values.push("__inlineFreeTextValue__")
  }
  return [{ type: keyType, context, sourceKey, values }]
}

function getSetStateChatbotProperties(
  dialogue: string,
  node: IDashboardNode
): IChatbotPropertyKey[] {
  const sourceKey = node.settings?.actionStateKey
  if (!sourceKey?.length) {
    Logger.getInstance().exception(
      new Error(`Action state key not found for node ${node.id}`),
      "getSetStateChatbotProperties failed"
    )
    console.error(`Action state key not found for node ${node.id}`)
  }
  invariant(sourceKey, `Action state key not found for node ${node.id}`)

  const type = node.settings?.actionStateValueType
  const values = type === "boolean" ? [true, false] : []
  const context = `${dialogue}-->${node.id}`
  return [{ type: KeyType.Text, context, sourceKey, values }]
}
