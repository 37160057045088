import React, { MouseEvent } from "react"
import { useFlowStore, useNodeEditorStore } from "../../../../../context/rootStoreContext"
import { NodeProps, Position } from "react-flow-renderer"
import classNames from "classnames"
import { CustomNode } from "../CustomNode"
import NodeSettings from "../NodeSettings"
import useDeleteNode from "../../../../../hooks/useDeleteNode"
import { styled } from "@stitches/react"
import DummyInput from "../../../components/QuestionEditor/components/DummyInput"
import { EditorType } from "../../../models/NodeEditors"
import Choices from "./Choices"
import BotLogo from "../../../../../assets/bot-logo.png"
import CheckboxChoices from "./CheckboxChoices"

interface IProps extends NodeProps {
  value?: string
  editable?: boolean
  onChange?: (value?: string) => void
  onEditingChange?: (editing: boolean) => void
}

const sourceStyle = ({ position, style }) => ({ ...style, ...styleMap[position] })

export default function QuestionNode(props: IProps) {
  const { id, data, selected, dragging } = props
  const onDelete = useDeleteNode(id)
  const nodeEditorStore = useNodeEditorStore()
  const flowStore = useFlowStore()
  const node = flowStore.getNode(id)
  const messages = typeof data.label === "string" ? [data.label] : data.label

  const onDoubleClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    nodeEditorStore.setEditorOpen(EditorType.QUESTION, id)
  }

  return (
    <CustomNode.Container
      id={id}
      dynamicHandles
      dragging={dragging}
      sourceHandleStyle={sourceStyle}
      targetHandleStyle={sourceStyle}>
      <Container
        choices={data.choices && data.choices.length > 10}
        className={classNames("react-flow__node-default", { selected })}>
        <CustomNode.CloneButton onClick={() => data.onDuplicate(id)} title="Clone">
          <span style={{ fontSize: "12px", marginTop: "-2px" }}>+</span>
        </CustomNode.CloneButton>
        <CustomNode.NodeId questionNode>{data.nodeId}</CustomNode.NodeId>
        {messages.map((message, index) => (
          <CustomNode.MessagesContainer key={message + index}>
            {index === 0 && <LogoImage alt="Limbic" src={BotLogo} />}{" "}
            <CustomNode.BotMessage>
              <span>{message}</span>
            </CustomNode.BotMessage>
          </CustomNode.MessagesContainer>
        ))}
        {data.type === "checkbox" ? <CheckboxChoices data={data} /> : null}
        {data.type === "inlinePicker" || data.type === "inlinePickerMultiSelect" ? (
          <Choices data={data} />
        ) : null}
        <DummyInput
          nodeId={id}
          promptType={data.type}
          hasTextPromptWithInlinePicker={node?.settings?.promptSettings?.textPromptWithInlineOption}
          size="small"
          crisisEnabled={node?.settings?.promptSettings?.crisisDetection}
        />
      </Container>
      <NodeSettings open={props.selected && !props.dragging} onDelete={onDelete} />
      <DoubleClickOverlay onDoubleClick={onDoubleClick} />
    </CustomNode.Container>
  )
}

const LogoImage = styled("img", {
  position: "absolute",
  left: 0,
  top: 0,
  height: "32px"
})

const DoubleClickOverlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

const horizontalHandleStyle = {
  width: 20,
  height: 10,
  borderRadius: 0
}

const verticalHandleStyle = {
  width: 10,
  height: 20,
  borderRadius: 0
}

const leftHandleStyle = {
  ...verticalHandleStyle,
  left: "-9px"
}

const rightHandleStyle = {
  ...verticalHandleStyle,
  right: "-9px"
}

const topHandleStyle = {
  ...horizontalHandleStyle,
  top: "-9px"
}

const bottomHandleStyle = {
  ...horizontalHandleStyle,
  bottom: "-9px"
}

const styleMap = {
  [Position.Left]: leftHandleStyle,
  [Position.Right]: rightHandleStyle,
  [Position.Top]: topHandleStyle,
  [Position.Bottom]: bottomHandleStyle
}

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
  width: "auto",
  maxWidth: "35vw",
  backgroundColor: "#F5F5F8 !important",
  variants: {
    choices: { true: { maxWidth: "60vw" } }
  }
})
