import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react"
import { ContextMenu } from "../../components/ContextMenu/ContextMenu"
import { observer } from "mobx-react"
import { useFlowStore } from "../../../../context/rootStoreContext"
import Vector2 from "../../../../../models/Vector2"
import { useReactFlowInstance } from "../../../../hooks/useReactFlowInstance"
import { NodeTypes } from "@limbic/types"

const { Root, Trigger, Content, Label, Item, Sub, SubTrigger, SubContent, Separator } = ContextMenu

function MainContextMenu(props: PropsWithChildren<{ children?: ReactNode | undefined }>) {
  const [clickCoords, setClickCoords] = useState<Vector2 | undefined>()
  const ref = useRef<HTMLDivElement>(null)
  const flowStore = useFlowStore()
  const rfInstance = useReactFlowInstance()

  const onOpenChange = useCallback((open: boolean) => !open && setClickCoords(undefined), [])

  useEffect(() => {
    const trigger = ref.current
    const handleClick = (e: MouseEvent) => {
      e.preventDefault()
      const bounds = ref.current!.getBoundingClientRect()
      const coords = rfInstance?.project({ x: e.clientX - bounds.left, y: e.clientY - bounds.top })
      setClickCoords(coords)
    }
    trigger?.addEventListener("mousedown", handleClick)
    return () => {
      trigger?.removeEventListener("mousedown", handleClick)
    }
  }, [rfInstance])

  return (
    <Root onOpenChange={onOpenChange}>
      <Trigger asChild ref={ref}>
        {props.children}
      </Trigger>
      <Content sideOffset={5} align="end">
        <Label>Tools</Label>
        <Item label="Save" right="⌘+S" />

        <Sub>
          <SubTrigger label="Create" />
          <SubContent sideOffset={2} alignOffset={-5}>
            <Item
              disabled={flowStore.nodes.some(n => n.type === NodeTypes.FlowStart)}
              label="Flow Start"
              onClick={() => flowStore.addNode(NodeTypes.FlowStart, { position: clickCoords })}
            />
            <Item
              label="Bot Question"
              onClick={() =>
                flowStore.addNode(NodeTypes.Question, {
                  label: "Double click to edit this question",
                  position: clickCoords
                })
              }
            />
            <Item
              label="Basic Condition"
              onClick={() =>
                flowStore.addNode(NodeTypes.Condition, {
                  label: "Basic Condition",
                  position: clickCoords
                })
              }
            />
            <Item
              label="Advanced Condition"
              onClick={() =>
                flowStore.addNode(NodeTypes.AdvancedCondition, {
                  label: "Adv. Condition",
                  position: clickCoords
                })
              }
            />
            <Item
              label="Action"
              onClick={() =>
                flowStore.addNode(NodeTypes.Action, {
                  label: "Action",
                  position: clickCoords
                })
              }
            />
            <Item
              label="Track Ineligible User"
              onClick={() =>
                flowStore.addNode(NodeTypes.IneligibleUser, {
                  label: "Set Ineligible User",
                  position: clickCoords
                })
              }
            />
            <Item
              label="Default Chat Flow"
              onClick={() =>
                flowStore.addNode(NodeTypes.ChatFlow, {
                  label: "Default Chat Flow",
                  position: clickCoords
                })
              }
            />
            <Item
              disabled={flowStore.nodes.some(n => n.type === NodeTypes.FlowEnd)}
              label="Flow End"
              onClick={() => flowStore.addNode(NodeTypes.FlowEnd, { position: clickCoords })}
            />
            <Item
              disabled={flowStore.nodes.some(n => n.type === NodeTypes.EndChat)}
              label="End Chat"
              onClick={() => flowStore.addNode(NodeTypes.EndChat)}
            />
          </SubContent>
        </Sub>
        <Separator />

        <Label>View</Label>
        <Item label="Fit view" right="⌘+Enter" />
        <Item label="Zoom In" right="⌘+[" />
        <Item label="Zoom Out" right="⌘+]" />
      </Content>
    </Root>
  )
}

export default observer(MainContextMenu)
