import React, { ChangeEvent } from "react"
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import { EditorType } from "../../../../models/NodeEditors"
import { StateValueType } from "@limbic/types"
import styles from "./SetStateKey.module.scss"

const DEFAULT_STATE_KEYS = [
  "gp",
  "birthday",
  "isUnderAged",
  "isEligible",
  "isMaybeEligible",
  "signPostToManualReferral",
  "needsToCall",
  "needsAssessmentCall",
  "assessmentCallReason",
  "canKeepSelfSafe",
  "experiencingCrisisAndSuicidalThoughts",
  "consentADSM",
  "readTheInformationSheetADSM",
  "agreeToWithdrawByEmailADSM",
  "agreeReferralDataToBeUsedADSM",
  "wishToParticipateInTheProjectADSM",
  "consentDataUsedInOtherProjectsADSM",
  "consentParticipationInProjectADSM",
  "agreeTerms",
  "agreeDetailsShared",
  "consentResearch",
  "requiresUrgentSupport",
  "currentMHTreatment",
  "currentMHTreatmentDetails"
]

function SetStateKey(): JSX.Element | null {
  const nodeEditorStore = useNodeEditorStore()
  const { actionEditorState } = nodeEditorStore
  const { actionStateKey, actionStateValueType, actionStateValue } = actionEditorState

  const updateState = data => {
    nodeEditorStore.updateState(EditorType.ACTION, data)
  }

  const handleStateKeyChange = (value: string): void => {
    updateState({ actionStateKey: value })
  }

  const handleStateBooleanValueChange = (event: SelectChangeEvent<string>): void => {
    const value = event.target.value
    updateState({ actionStateValue: value })
  }

  const handleStateStringValueChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const value = event.target.value
    updateState({ actionStateValue: value })
  }

  const handleStateValueTypeChange = (event: SelectChangeEvent<StateValueType>): void => {
    const value = event.target.value as StateValueType
    updateState({ actionStateValue: "", actionStateValueType: value })
  }

  return (
    <div className={styles.setStateContainer}>
      <FormControl className={styles.formControlStateKey} fullWidth>
        <Autocomplete
          freeSolo
          multiple={false}
          id="action-state-key"
          onChange={(_event, value) => handleStateKeyChange(value!)}
          options={DEFAULT_STATE_KEYS}
          value={actionStateKey}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              onChange={event => handleStateKeyChange(event.target.value)}
              variant="standard"
              label="State Key"
              placeholder="Type a state key"
            />
          )}
        />
      </FormControl>
      <div className={styles.valueContainer}>
        <FormControl className={styles.formControlStateType}>
          <InputLabel id="state-value-type-label">State value type</InputLabel>
          <Select
            labelId="state-value-type-label"
            id="state-value-type-select"
            value={actionStateValueType}
            label="Type of value"
            onChange={handleStateValueTypeChange}>
            <MenuItem value="boolean">Boolean (true or false)</MenuItem>
            <MenuItem value="string">Text</MenuItem>
            <MenuItem value="number">Number</MenuItem>
          </Select>
        </FormControl>
        {actionStateValueType === "boolean" && (
          <FormControl className={styles.formControlStateValue}>
            <InputLabel id="state-value-boolean-label">State value</InputLabel>
            <Select
              labelId="state-value-boolean-label"
              id="state-value-boolean-select"
              value={actionStateValue}
              label="Value"
              onChange={handleStateBooleanValueChange}>
              <MenuItem value="true">true</MenuItem>
              <MenuItem value="false">false</MenuItem>
            </Select>
          </FormControl>
        )}
        {actionStateValueType === "string" && (
          <FormControl className={styles.formControlStateValue}>
            <TextField
              variant="standard"
              label="State value"
              placeholder="Type in a custom value"
              value={actionStateValue}
              onChange={event => handleStateStringValueChange(event)}
            />
          </FormControl>
        )}
        {actionStateValueType === "number" && (
          <FormControl className={styles.formControlStateValue}>
            <TextField
              type="number"
              variant="standard"
              value={actionStateValue}
              label="State value"
              placeholder="Type in a custom value"
              onChange={event => handleStateStringValueChange(event)}
            />
          </FormControl>
        )}
      </div>
    </div>
  )
}

export default SetStateKey
