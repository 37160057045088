import React from "react"
import { observer } from "mobx-react"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import FormControlWithCheckBox from "../FormControlWithCheckBox"

function AskCovidDetails(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <FormControlWithCheckBox
        name="askHasLongCovid"
        label="Ask if the user has long covid"
        labelPlacement="start"
        updateState={updateState}
      />
      <FormControlWithCheckBox
        name="askIsReferralForLongCovid"
        label="Ask if the user's referral relates to long covid"
        labelPlacement="start"
        updateState={updateState}
      />
    </>
  )
}

export default observer(AskCovidDetails)
