import React, { useEffect } from "react"
import { Autocomplete, FormControl, TextField, Typography } from "@mui/material/"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Button from "../../../Button"
import { useFlowStore, useNodeEditorStore } from "../../../../context/rootStoreContext"
import { chatFlowsMap, EditorType } from "../../models/NodeEditors"
import { observer } from "mobx-react"
import { DEFAULT_SETTINGS } from "../../../../stores/NodeEditorStore"
import { ChatFlowsEnum, DiscussionSteps, ISelectableExtended } from "@limbic/types"
import ChatFlowSettings from "./components/ChatFlowSettings"
import ChatFlowOptions from "./components/ChatFlowOptions/ChatFlowOptions"
import sortObjectAlphabetically from "../../../../../utils/sortObjectAlphabetically"
import optionsMap from "./constants/options"
import CustomDrawer from "../../../Drawer/Drawer"
import styles from "./ChatFlowEditor.module.scss"
import { FF, hasFF } from "../../../../../featureFlags"

export function getChatFlowOptions() {
  return Object.entries(chatFlowsMap)
    .filter(([key]) => {
      switch (key) {
        case ChatFlowsEnum.QUESTIONNAIRES:
          return hasFF(FF.AC908_STANDALONE_QUESTIONNAIRE)
        case ChatFlowsEnum.COLLECT_US_ADDRESS:
          return hasFF(FF.AC907_US_ADDRESS)
        default:
          return true
      }
    })
    .map(([id, label]) => ({ id, label }))
    .sort(sortObjectAlphabetically("label"))
    .map(({ id }) => id) as ChatFlowsEnum[]
}

// this is done purely for testing purposes
// so we can mock the flags and assert the
// correct options are returned each time
const CHATFLOW_OPTIONS = getChatFlowOptions()

function ChatFlowEditor(): JSX.Element {
  const flowStore = useFlowStore()
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlow, chatFlowSettings } = chatFlowEditorState

  const hasServiceSearch = !!flowStore.nodes.find(
    node => node.type === "chatFlow" && node.settings?.chatFlow === ChatFlowsEnum.SERVICE_SEARCH
  )

  const hasSpineSearch = !!flowStore.nodes.find(
    node => node.type === "chatFlow" && node.settings?.chatFlow === ChatFlowsEnum.SPINE_SEARCH
  )

  const showChatFlowInputForOptions =
    chatFlow === ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS ||
    chatFlow === ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL ||
    chatFlow === ChatFlowsEnum.COLLECT_ETHNICITY ||
    chatFlow === ChatFlowsEnum.COLLECT_NATIONALITY ||
    chatFlow === ChatFlowsEnum.COLLECT_GENDER ||
    chatFlow === ChatFlowsEnum.COLLECT_SEXUALITY ||
    chatFlow === ChatFlowsEnum.COLLECT_LANGUAGE ||
    chatFlow === ChatFlowsEnum.COLLECT_DISABILITY ||
    chatFlow === ChatFlowsEnum.COLLECT_RELIGION

  const showCanBeSelectedIndividually =
    chatFlow === ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS ||
    chatFlow === ChatFlowsEnum.ASK_CURRENT_MH_PROFESSIONAL

  const hasSecondaryOptions = chatFlow === ChatFlowsEnum.COLLECT_GENDER

  useEffect(() => {
    const nodeId = nodeEditorStore.nodeId
    const currentNode = flowStore.nodes.find(node => node.id === nodeId)

    // TODO: this looks like it's here to sync the updates
    //      from the node editor to the flow store but it
    //      feels like a code smell. We should be able to
    //      sync the two stores outside of a useEffect
    if (Object.keys(currentNode?.settings ?? {}).length) {
      const { settings } = currentNode!
      const { chatFlow, chatFlowSettings } = settings!
      nodeEditorStore.updateState(EditorType.CHAT_FLOW, {
        chatFlow,
        chatFlowSettings: {
          ...DEFAULT_SETTINGS.chatFlow.chatFlowSettings,
          ...chatFlowSettings,
          messages: {
            ...DEFAULT_SETTINGS.chatFlow.chatFlowSettings.messages,
            ...chatFlowSettings?.messages
          }
        }
      })
    } else {
      nodeEditorStore.resetState(EditorType.CHAT_FLOW)
    }
  }, [flowStore.chatFlowEditorNodeID, flowStore.nodes, flowStore.edges, nodeEditorStore])

  const handleClose = (): void => {
    nodeEditorStore.setEditorClosed(EditorType.CHAT_FLOW)
  }

  const handleSave = (): void => {
    const nodeId = nodeEditorStore.nodeId
    const label = chatFlowsMap[chatFlow]
    flowStore.updateChatFlowNode(nodeId, chatFlowEditorState, label, chatFlow)
  }

  const handleChatFlowChange = (value: string): void => {
    nodeEditorStore.updateChatFlowState({ chatFlow: value })
  }

  const getOptions = (): ISelectableExtended[] => {
    if (chatFlowSettings[chatFlow] && chatFlowSettings[chatFlow].choicesMap?.length) {
      return chatFlowSettings[chatFlow].choicesMap
    }
    return optionsMap[chatFlow]
  }

  const getSecondaryOptions = (): ISelectableExtended[] => {
    if (chatFlowSettings[chatFlow] && chatFlowSettings[chatFlow].secondaryChoicesMap?.length) {
      return chatFlowSettings[chatFlow].secondaryChoicesMap
    }
    return optionsMap[`${chatFlow}Secondary`]
  }

  const checkDisabledChatFlow = (flow: ChatFlowsEnum): boolean => {
    if (flow === chatFlow) return true

    const isEligibility = [ChatFlowsEnum.SPINE_SEARCH, ChatFlowsEnum.SERVICE_SEARCH].includes(flow)
    const isCrisisDetection = flow === ChatFlowsEnum.CHECK_CRISIS_DETECTION
    const isHighLevelCrisis = flowStore.currentHighLevelDialogue.value === DiscussionSteps.Crisis
    return (
      (isEligibility && (hasServiceSearch || hasSpineSearch)) ||
      (isCrisisDetection && !isHighLevelCrisis)
    )
  }

  return (
    <CustomDrawer
      anchor="left"
      isDrawerOpen={nodeEditorStore.chatFlowEditorOpen}
      onClose={handleClose}>
      <DialogContent>
        <div className={styles.nodeEditorContainer}>
          <div className={styles.optionsContainer}>
            <Typography>Options</Typography>
            <div className={styles.optionsContent}>
              <div className={styles.formControl}>
                <FormControl className={styles.formControl} fullWidth>
                  <Autocomplete<ChatFlowsEnum>
                    filterSelectedOptions
                    id="selected-chat-flow"
                    className={styles.autocompleteOptions}
                    options={CHATFLOW_OPTIONS}
                    value={chatFlow}
                    onChange={(_event, value) => handleChatFlowChange(value!)}
                    getOptionLabel={chatFlow => chatFlowsMap[chatFlow]}
                    getOptionDisabled={option => checkDisabledChatFlow(option)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Chat Flow"
                        placeholder="Please select a default chat flow"
                      />
                    )}
                  />
                </FormControl>
              </div>
              <ChatFlowOptions
                showChatFlowInputForOptions={showChatFlowInputForOptions}
                showCanBeSelectedIndividually={showCanBeSelectedIndividually}
                options={getOptions()}
                secondaryOptions={hasSecondaryOptions ? getSecondaryOptions() : undefined}
              />
              <ChatFlowSettings chatFlow={chatFlow} />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={styles.dialogActionsContainer}>
          <div className={styles.dialogActionsErrorMessage}>
            {nodeEditorStore.isSaveButtonDisabled.disabledReason}
          </div>
          <div>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={handleSave} disabled={nodeEditorStore.isSaveButtonDisabled.disabled}>
              Save
            </Button>
          </div>
        </div>
      </DialogActions>
    </CustomDrawer>
  )
}

export default observer(ChatFlowEditor)
