import React, { MouseEvent } from "react"
import { NodeProps, Position } from "react-flow-renderer"
import classNames from "classnames"
import { styled } from "@stitches/react"
import { CustomNode } from "../CustomNode"
import NodeSettings from "../NodeSettings"
import useDeleteNode from "../../../../../hooks/useDeleteNode"
import SimpleText from "../../../components/Text"
import HexagonShape from "../../../components/shapes/HexagonShape"
import { useFlowStore, useNodeEditorStore } from "../../../../../context/rootStoreContext"
import { EditorType } from "../../../models/NodeEditors"

const sourceStyle = ({ position, style }) => ({ ...style, ...styleMap[position] })

export default function ActionNode(props: NodeProps) {
  const { id, data, selected, dragging } = props
  const onDelete = useDeleteNode(id)
  const nodeEditorStore = useNodeEditorStore()
  const flowStore = useFlowStore()

  const nextDialogue = flowStore.getNode(id)?.settings?.nextDialogue
  const nextDialogueName = nextDialogue?.replace(/([A-Z])/g, " $1").toLowerCase()

  const label = nextDialogueName
    ? `Go to: ${
        nextDialogueName?.length > 15 ? nextDialogueName.substring(0, 15) + "..." : nextDialogueName
      }`
    : data.label

  const onDoubleClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    nodeEditorStore.setEditorOpen(EditorType.ACTION, id)
  }

  return (
    <CustomNode.Container
      dynamicHandles
      id={id}
      dragging={dragging}
      sourceHandleStyle={sourceStyle}
      targetHandleStyle={sourceStyle}>
      <Container
        className={classNames("react-flow__node-shape nopan", { selected, nodrag: false })}>
        <CustomNode.NodeId actionNode>{id}</CustomNode.NodeId>
        <HexagonShape fillColor={"#edc265"} strokeWidth={selected ? 1.5 : 1} />
        <AbsoluteContainer>
          <CustomNode.Label>
            <SimpleText width="100%" value={label} />
          </CustomNode.Label>
        </AbsoluteContainer>
      </Container>
      <NodeSettings open={props.selected && !props.dragging} onDelete={onDelete} />
      <DoubleClickOverlay onDoubleClick={onDoubleClick} />
    </CustomNode.Container>
  )
}

const DoubleClickOverlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center"
})

const AbsoluteContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: "100%",
  height: "100%"
})

const rootHandleStyle = {
  height: "14px",
  width: "14px",
  borderColor: "black"
}

const leftHandleStyle = {
  ...rootHandleStyle,
  left: "-6px"
}

const rightHandleStyle = {
  ...rootHandleStyle,
  right: "-6px"
}

const topHandleStyle = {
  ...rootHandleStyle,
  top: "-6px"
}

const bottomHandleStyle = {
  ...rootHandleStyle,
  bottom: "-6px"
}

const styleMap = {
  [Position.Left]: leftHandleStyle,
  [Position.Right]: rightHandleStyle,
  [Position.Top]: topHandleStyle,
  [Position.Bottom]: bottomHandleStyle
}
