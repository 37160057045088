import React, { ReactElement, useState } from "react"
import { observer } from "mobx-react"
import { ChatFlowsEnum } from "@limbic/types"
import CustomizedAccordion from "../../../../../Accordion/Accordion"
import CollectEmail from "../CollectEmail"
import CollectMainIssue from "../CollectMainIssue"
import CollectLanguage from "../CollectLanguage"
import CollectNHSNumber from "../CollectNHSNumber"
import CollectDisability from "../CollectDisability"
import CollectSexuality from "../CollectSexuality"
import CollectGender from "../CollectGender"
import CollectPhoneNumber from "../CollectPhoneNumber"
import CollectPreferredCorrespondence from "../CollectPreferredCorrespondence"
import CollectLTC from "../CollectLTC"
import CollectGoalForTherapy from "../CollectGoalForTherapy"
import CollectADHD from "../CollectADHD"
import CollectASD from "../CollectASD"
import CollectAlcohol from "../CollectAlcohol"
import CollectSubstances from "../CollectSubstances"
import CollectDateOfBirth from "../CollectDateOfBirth"
import CollectName from "../CollectName"
import CollectFeedback from "../CollectFeedback"
import CheckPostCodeFromAddressLookup from "../CheckPostCodeFromAddressLookup"
import AskPriorMHTreatment from "../AskPriorMHTreatment"
import CollectNationality from "../CollectNationality"
import SubmitReferral from "../SubmitReferral"
import ServiceSearch from "../SeviceSearch"
import SpineSearch from "../SpineSearch"
import CheckCrisisDetection from "../CheckCrisisDetection"
import GetPermissions from "../GetPermissions/GetPermissions"
import CollectReligion from "../CollectReligion"
import AssessmentAndTreatments from "../AssessmentAndTreatments/AssessmentAndTreatments"
import GoodbyeRecap from "../GoodbyeRecap"
import BookAppointment from "../BookAppointment"
import CollectSMI from "../CollectSMI/CollectSMI"
import AssessmentAndTreatmentsCustomQuestionnaires from "../AssessmentAndTreatmentsCustomQuestionnaires/AssessmentAndTreatmentsCustomQuestionnaires"
import Questionnaires from "../Questionnaires"
import CollectUSAddress from "../CollectUSAddress/CollectUSAddress"
import AdministerCSSRS from "../AdministerCSSRS"
import AskCovidDetails from "../AskCovidDetails"

interface Props {
  chatFlow: ChatFlowsEnum
}

function ChatFlowSettings({ chatFlow }: Props): JSX.Element | null {
  const [settingsExpanded, setSettingsExpanded] = useState(true)

  const getSettings = (): ReactElement | null => {
    switch (chatFlow) {
      case ChatFlowsEnum.ASK_COVID_DETAILS:
        return <AskCovidDetails />
      case ChatFlowsEnum.COLLECT_EMAIL:
        return <CollectEmail />
      case ChatFlowsEnum.COLLECT_PREFERRED_CORRESPONDENCE:
        return <CollectPreferredCorrespondence />
      case ChatFlowsEnum.COLLECT_PHONE_NUMBER:
        return <CollectPhoneNumber />
      case ChatFlowsEnum.COLLECT_GENDER:
        return <CollectGender />
      case ChatFlowsEnum.COLLECT_SEXUALITY:
        return <CollectSexuality />
      case ChatFlowsEnum.COLLECT_DISABILITY:
        return <CollectDisability />
      case ChatFlowsEnum.COLLECT_SUBSTANCES:
        return <CollectSubstances />
      case ChatFlowsEnum.COLLECT_NHS_NUMBER:
        return <CollectNHSNumber />
      case ChatFlowsEnum.COLLECT_MAIN_ISSUE:
        return <CollectMainIssue />
      case ChatFlowsEnum.COLLECT_LANGUAGE:
        return <CollectLanguage />
      case ChatFlowsEnum.ASK_LONG_TERM_MEDICAL_CONDITIONS:
        return <CollectLTC />
      case ChatFlowsEnum.COLLECT_GOAL_FOR_THERAPY:
        return <CollectGoalForTherapy />
      case ChatFlowsEnum.COLLECT_ADHD:
        return <CollectADHD />
      case ChatFlowsEnum.COLLECT_ASD:
        return <CollectASD />
      case ChatFlowsEnum.CHECK_ALCOHOL_CONSUMPTION:
        return <CollectAlcohol />
      case ChatFlowsEnum.CHECK_POSTCODE_FROM_ADDRESS_LOOKUP:
        return <CheckPostCodeFromAddressLookup />
      case ChatFlowsEnum.COLLECT_US_ADDRESS:
        return <CollectUSAddress />
      case ChatFlowsEnum.ASK_PREVIOUS_MH_TREATMENT:
        return <AskPriorMHTreatment />
      case ChatFlowsEnum.COLLECT_NATIONALITY:
        return <CollectNationality />
      case ChatFlowsEnum.COLLECT_DATE_OF_BIRTH:
        return <CollectDateOfBirth />
      case ChatFlowsEnum.COLLECT_NAME:
        return <CollectName />
      case ChatFlowsEnum.COLLECT_FEEDBACK:
        return <CollectFeedback />
      case ChatFlowsEnum.COLLECT_RELIGION:
        return <CollectReligion />
      case ChatFlowsEnum.CHECK_CRISIS_DETECTION:
        return <CheckCrisisDetection />
      case ChatFlowsEnum.SUBMIT_REFERRAL:
        return <SubmitReferral />
      case ChatFlowsEnum.SERVICE_SEARCH:
        return <ServiceSearch />
      case ChatFlowsEnum.SPINE_SEARCH:
        return <SpineSearch />
      case ChatFlowsEnum.GET_PERMISSIONS:
        return <GetPermissions />
      case ChatFlowsEnum.ASSESSMENT_AND_TREATMENTS:
        return <AssessmentAndTreatments />
      case ChatFlowsEnum.ASSESSMENT_CUSTOMISABLE_ADSM:
        return <AssessmentAndTreatmentsCustomQuestionnaires />
      case ChatFlowsEnum.QUESTIONNAIRES:
        return <Questionnaires />
      case ChatFlowsEnum.BOOK_APPOINTMENT:
        return <BookAppointment />
      case ChatFlowsEnum.GOODBYE_RECAP:
        return <GoodbyeRecap />
      case ChatFlowsEnum.COLLECT_SMI:
        return <CollectSMI />
      case ChatFlowsEnum.ADMINISTER_CSSRS:
        return <AdministerCSSRS />
      default:
        console.log("No settings for ", chatFlow)
        return null
    }
  }

  const settings = getSettings()
  if (!settings) return null

  return (
    <div style={{ marginTop: "8px" }}>
      <CustomizedAccordion
        summary="Other Settings"
        expanded={settingsExpanded}
        onPanelChange={(_, expanded) => setSettingsExpanded(expanded)}
        maxHeight="none">
        {settings}
      </CustomizedAccordion>
    </div>
  )
}

export default observer(ChatFlowSettings)
