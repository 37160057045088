import React from "react"
import Button from "../../../../components/Button"
import { FormControl, TextField } from "@mui/material"
import Dialog from "../../../../components/Dialog"
import { observer } from "mobx-react"
import { useFlowStore, useServiceStore } from "../../../../context/rootStoreContext"
import ChipInput from "../../../../components/ChipInput"
import { createService } from "../../../../../backend/api/service"
import delay from "../../../../../utils/delay"
import { ServiceStatus } from "../../../../../models/Service"

import styles from "./CreateService.module.scss"

interface Props {
  goToDashboard: (key: string) => void
  setCreateServiceDialogueOpen: (value: boolean) => void
  createServiceDialogueOpen: boolean
}

function SelectService(props: Props): JSX.Element {
  const serviceStore = useServiceStore()
  const flowStore = useFlowStore()
  const [serviceName, setServiceName] = React.useState<string>("")
  const [serviceKey, setServiceKey] = React.useState<string>("")
  const [allowCreateService, setAllowCreateService] = React.useState<boolean>(false)
  const [botApiKeys, setBotApiKeys] = React.useState<string[]>([])
  const [serviceNameExists, setServiceNameExists] = React.useState<boolean>(false)
  const [serviceKeyExists, setServiceKeyExists] = React.useState<boolean>(false)
  const [botApiKeyExists, setBotApiKeyExists] = React.useState<boolean>(false)
  const [botApiKeyThatExists, setBotApiKeyThatExists] = React.useState<string>("")

  React.useEffect(() => {
    return () => {
      setServiceName("")
      setServiceKey("")
      setBotApiKeys([])
    }
  }, [props.createServiceDialogueOpen])

  React.useEffect(() => {
    const currentServices = serviceStore.services
    if (currentServices?.find(s => s.name.toLowerCase() === serviceName.toLowerCase())) {
      setServiceNameExists(true)
    }
    if (currentServices?.find(s => s.serviceApiKey.toLowerCase() === serviceKey.toLowerCase())) {
      setServiceKeyExists(true)
    }
    if (serviceName.length > 2 && serviceKey.length > 2 && botApiKeys.length > 0) {
      setAllowCreateService(true)
    } else {
      setAllowCreateService(false)
    }
  }, [serviceName, serviceKey, botApiKeys, serviceStore.services])

  const handleCreateService = async () => {
    let trimmedServiceKey = serviceKey
    if (serviceKey[serviceKey.length - 1] === "-") trimmedServiceKey = serviceKey.slice(0, -1)
    await createService(serviceName, trimmedServiceKey, botApiKeys)
    props.setCreateServiceDialogueOpen(false)
    serviceStore.setIsLoading(true)
    await delay(1)
    const getServicesStatus = await serviceStore.getServices()
    if (getServicesStatus === ServiceStatus.Success) {
      serviceStore.setServiceKey(serviceKey)
      if (serviceStore.serviceKey) {
        await serviceStore.updateHighLevelFlowConfiguration(flowStore.highLevelFlow)
      }
      props.goToDashboard(serviceKey)
    }
  }

  const handleInputChange = (event, type: "serviceName" | "serviceKey") => {
    switch (type) {
      case "serviceName":
        setServiceNameExists(false)
        setServiceName(event.target.value)
        break
      case "serviceKey":
        {
          setServiceKeyExists(false)
          const regex = /[^a-zA-Z0-9]/g
          let formattedKey = event.target.value.replace(regex, "-")
          const lastCharIndex = formattedKey.length - 1
          if (formattedKey[lastCharIndex] === "-" && formattedKey[lastCharIndex - 1] === "-") {
            formattedKey = formattedKey.slice(0, -1)
          }
          const key = formattedKey.toLowerCase()
          setServiceKey(key)
        }
        break
      default:
        break
    }
  }

  const handleAddBotKey = (value: string) => {
    const regex = /[^a-zA-Z0-9]/g
    const snakeCase = value.replace(regex, "_")
    let formattedValue = snakeCase.toUpperCase()
    const lastCharIndex = formattedValue.length - 1
    if (formattedValue[lastCharIndex] === "_") {
      formattedValue = formattedValue.slice(0, -1)
    }
    if (serviceStore.services?.find(s => s.botApiKeys.includes(formattedValue))) {
      setBotApiKeyThatExists(formattedValue)
      setBotApiKeyExists(true)
    } else {
      const updatedValues = [...botApiKeys, formattedValue]
      setBotApiKeyThatExists("")
      setBotApiKeys(updatedValues)
    }
  }

  const handleDeleteBotKey = (value: string): void => {
    const updatedValues = botApiKeys.filter(option => option !== value)
    setBotApiKeys(updatedValues)
  }

  const handleOnChipInputChange = () => {
    setBotApiKeyExists(false)
  }

  return (
    <Dialog
      ActionButton={
        <Button disabled={!allowCreateService} onClick={handleCreateService}>
          Create
        </Button>
      }
      onClose={() => props.setCreateServiceDialogueOpen(false)}
      open={props.createServiceDialogueOpen}
      title="Create a new service"
      maxWidth="sm">
      <div className={styles.createServiceWrapper}>
        <span>Please add the details below and then click create</span>
        <div className={styles.createServiceInputContainer}>
          <FormControl className={styles.formControlCreateServiceInput}>
            <TextField
              error={serviceNameExists}
              helperText={serviceNameExists ? "Service name already exists" : ""}
              id="service-name"
              label="Service name (i.e. Demo Talking Therapies)"
              variant="outlined"
              value={serviceName}
              onChange={event => handleInputChange(event, "serviceName")}
              inputProps={{
                "data-testid": "service-name-input"
              }}
            />
          </FormControl>
        </div>
        <div className={styles.createServiceInputContainer}>
          <FormControl className={styles.formControlCreateServiceInput}>
            <TextField
              error={serviceKeyExists}
              helperText={serviceKeyExists ? "Service key already exists" : ""}
              id="service-key"
              label="Service key (in the form abc-def-ghi)"
              variant="outlined"
              value={serviceKey}
              onChange={event => handleInputChange(event, "serviceKey")}
              inputProps={{
                "data-testid": "service-key-input"
              }}
            />
          </FormControl>
        </div>
        <div className={styles.createServiceInputContainer}>
          <ChipInput
            error={botApiKeyExists}
            helperText={
              botApiKeyExists ? `Bot API key '${botApiKeyThatExists}' already exists` : ""
            }
            onKeyDownCapture={handleOnChipInputChange}
            options={botApiKeys}
            onAdd={handleAddBotKey}
            onDelete={handleDeleteBotKey}
            label="Bot API Keys"
            placeholder="Add keys here (in the form ABC_XYZ)"
          />
        </div>
      </div>
    </Dialog>
  )
}

export default observer(SelectService)
