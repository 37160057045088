import React, { MouseEvent } from "react"
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Button,
  Chip,
  TextField
} from "@mui/material"
import styles from "./ConditionsBuilder.module.scss"
import {
  ClinicalConditions,
  GoodbyeRecapMessageKeys,
  StateConditions,
  IGoodbyeRecapMessageConditionChain
} from "@limbic/types"
import CustomizedAccordion from "../../../../../../../Accordion/Accordion"
import CancelIcon from "@mui/icons-material/Cancel"

interface Props {
  disableDelete?: boolean
  title: string
  conditionID: GoodbyeRecapMessageKeys
  conditionsExpanded: boolean
  recapConditions: IGoodbyeRecapMessageConditionChain
  deleteConditionsSet: (keyToRemove: GoodbyeRecapMessageKeys) => void
  handleConditionsToggle: (expanded: boolean) => void
  onHandleConditionChange: (
    type: true | false | "skip",
    message: GoodbyeRecapMessageKeys,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<ClinicalConditions | StateConditions> | undefined
  ) => void
}

function ConditionsBuilder(props: Props): JSX.Element {
  const deleteConditionsSet = (
    event: MouseEvent<HTMLButtonElement>,
    id: GoodbyeRecapMessageKeys
  ) => {
    event.stopPropagation()
    props.deleteConditionsSet(id)
  }

  return (
    <CustomizedAccordion
      className={styles.conditionsAccordion}
      summary={props.title}
      summaryButton={
        <Button
          variant="contained"
          color="secondary"
          endIcon={<CancelIcon />}
          className={styles.addButton}
          disabled={props.disableDelete}
          onClick={event => deleteConditionsSet(event, props.conditionID)}>
          Remove
        </Button>
      }
      expanded={props.conditionsExpanded}
      onPanelChange={(_, expanded: boolean) => props.handleConditionsToggle(expanded)}
      maxHeight="none"
      forceBorderBottom>
      <Autocomplete
        className={styles.autocompleteOptions}
        multiple
        id={`true-${props.conditionID}`}
        onChange={(_event, _value, reason, details) => {
          props.onHandleConditionChange(true, props.conditionID, reason, details)
        }}
        options={props.recapConditions.map(option => option.name)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              className={styles.recapConditionChip}
            />
          ))
        }
        value={props.recapConditions
          .filter(option => option.value === true)
          .map(option => option.name)}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="True"
            placeholder="Add conditions that are expected to be true"
          />
        )}
      />
      <Autocomplete
        className={styles.autocompleteOptions}
        multiple
        id={`false-${props.conditionID}`}
        onChange={(_event, _value, reason, details) => {
          props.onHandleConditionChange(false, props.conditionID, reason, details)
        }}
        options={props.recapConditions.map(option => option.name!)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              className={styles.recapConditionChip}
            />
          ))
        }
        value={props.recapConditions
          .filter(option => option.value === false)
          .map(option => option.name)}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="False"
            placeholder="Add conditions that are expected to be false"
          />
        )}
      />
      <Autocomplete
        className={styles.autocompleteOptions}
        multiple
        id={`skip-${props.conditionID}`}
        onChange={(_event, _value, reason, details) => {
          props.onHandleConditionChange("skip", props.conditionID, reason, details)
        }}
        options={props.recapConditions.map(option => option.name!)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              className={styles.recapConditionChip}
            />
          ))
        }
        value={props.recapConditions
          .filter(option => option.value === "skip")
          .map(option => option.name)}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="Skip"
            placeholder="Add conditions that are expected to be skipped"
          />
        )}
      />
    </CustomizedAccordion>
  )
}

export default ConditionsBuilder
