import React from "react"
import { observer } from "mobx-react"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import { Typography } from "@mui/material"
import SolidDivider from "../../../../../SolidDivider"
import styles from "./AdministerCSSRS.module.scss"
import MessageList from "../MessageList"
import MessageForm from "../MessageForm"

function AdministerCSSRS(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  return (
    <>
      <Typography className={styles.headingText} variant="h5" align="center">
        C-SSRS Survey Customisation
      </Typography>
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldSkipQuestion7"
        label={`Skip question 7 ("${messages?.cssrsQuestion7}")`}
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <Typography variant="h6" className={styles.questionText} align="center">
        Intro Message
      </Typography>
      <MessageList
        messages={messages?.cssrsIntro ?? []}
        messageType="cssrsIntro"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.cssrsIntro}
        placeholder="Type a message here..."
        name="cssrsIntro"
        updateState={updateState}
        messages={messages?.cssrsIntro ?? []}
      />
      <Typography variant="h6" className={styles.questionText} align="center">
        First Question
      </Typography>
      <MessageList
        messages={messages?.cssrsQuestion1 ?? []}
        messageType="cssrsQuestion1"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.cssrsQuestion1}
        placeholder="Type a message here..."
        name="cssrsQuestion1"
        updateState={updateState}
        messages={messages?.cssrsQuestion1 ?? []}
      />
      <Typography variant="h6" className={styles.questionText} align="center">
        Second Question
      </Typography>
      <MessageList
        messages={messages?.cssrsQuestion2 ?? []}
        messageType="cssrsQuestion2"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.cssrsQuestion2}
        placeholder="Type a message here..."
        name="cssrsQuestion2"
        updateState={updateState}
        messages={messages?.cssrsQuestion2 ?? []}
      />
      <Typography variant="h6" className={styles.questionText} align="center">
        Third Question
      </Typography>
      <MessageList
        messages={messages?.cssrsQuestion3 ?? []}
        messageType="cssrsQuestion3"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.cssrsQuestion3}
        placeholder="Type a message here..."
        name="cssrsQuestion3"
        updateState={updateState}
        messages={messages?.cssrsQuestion3 ?? []}
      />
      <Typography variant="h6" className={styles.questionText} align="center">
        Fourth Question
      </Typography>
      <MessageList
        messages={messages?.cssrsQuestion4 ?? []}
        messageType="cssrsQuestion4"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.cssrsQuestion4}
        placeholder="Type a message here..."
        name="cssrsQuestion4"
        updateState={updateState}
        messages={messages?.cssrsQuestion4 ?? []}
      />
      <Typography variant="h6" className={styles.questionText} align="center">
        Fifth Question
      </Typography>
      <MessageList
        messages={messages?.cssrsQuestion5 ?? []}
        messageType="cssrsQuestion5"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.cssrsQuestion5}
        placeholder="Type a message here..."
        name="cssrsQuestion5"
        updateState={updateState}
        messages={messages?.cssrsQuestion3 ?? []}
      />
      <Typography variant="h6" className={styles.questionText} align="center">
        Sixth Question
      </Typography>
      <MessageList
        messages={messages?.cssrsQuestion6 ?? []}
        messageType="cssrsQuestion6"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.cssrsQuestion6}
        placeholder="Type a message here..."
        name="cssrsQuestion6"
        updateState={updateState}
        messages={messages?.cssrsQuestion6 ?? []}
      />
      <Typography variant="h6" className={styles.questionText} align="center">
        Seventh Question
      </Typography>
      {chatFlowSettings.administerCSSRS?.shouldSkipQuestion7 && (
        <Typography variant="body2" className={styles.questionCaption} align="center">
          If this question is needed, please uncheck the setting "Skip question 7" above
        </Typography>
      )}
      <MessageList
        disabled={chatFlowSettings.administerCSSRS?.shouldSkipQuestion7}
        messages={messages?.cssrsQuestion7 ?? []}
        messageType="cssrsQuestion7"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        disabledInput={chatFlowSettings.administerCSSRS?.shouldSkipQuestion7}
        value={chatFlowSettings.currentMessage?.cssrsQuestion7}
        placeholder="Type a message here..."
        name="cssrsQuestion7"
        updateState={updateState}
        messages={messages?.cssrsQuestion7 ?? []}
      />
      <Typography className={styles.headingText} variant="h5" align="center">
        Other Settings
      </Typography>
      <SolidDivider />
      <FormControlWithCheckBox
        name="shouldUpdateReferral"
        label="Update the referral with the CSSRS responses on completion"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
    </>
  )
}

export default observer(AdministerCSSRS)
