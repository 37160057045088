import React, { useEffect } from "react"
import {
  Autocomplete,
  Dialog as MuiDialog,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "@mui/material/"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Button from "../../../Button"
import { useFlowStore, useNodeEditorStore } from "../../../../context/rootStoreContext"
import { Actions, EditorType } from "../../models/NodeEditors"
import { observer } from "mobx-react"
import { DiscussionSteps, NextDialogues } from "@limbic/types"
import SetStateKey from "./components/SetStateKey"
import SetIAPT from "./components/SetIAPT"
import SetLanguage from "./components/SetLanguage"
import styles from "./ActionEditor.module.scss"
import UpdateReferral from "./components/UpdateReferral/UpdateReferral"
import AddClinicalNote from "./components/AddClinicalNote/AddClinicalNote"
import SetRiskLevel from "./components/SetRiskLevel/SetRiskLevel"

const actionMap: Record<Actions, string> = {
  [Actions.TRACK]: "Track event in mixpanel",
  [Actions.SET_PEOPLE]: "Set people in mixpanel",
  [Actions.NEXT_DIALOGUE]: "Go to a new dialogue",
  [Actions.SET_STATE]: "Set state value",
  [Actions.SET_IAPT]: "Set IAPT",
  [Actions.UPDATE_REFERRAL]: "Update referral",
  [Actions.ADD_CLINICAL_NOTES]: "Add clinical notes",
  [Actions.SET_LANGUAGE]: "Set language",
  [Actions.SET_RISK_LEVEL]: "Set risk level",
  [Actions.SUBMIT_REFERRAL]: "Submit referral"
}

const createLabelValueArray = (): Array<{ label: string; value: string }> => {
  const array: Array<{ label: string; value: string }> = []

  for (const key in NextDialogues) {
    if (Object.prototype.hasOwnProperty.call(NextDialogues, key)) {
      const label = key
        .split("_")
        .map(word => word.charAt(0) + word.slice(1).toLowerCase())
        .join(" ")

      array.push({
        label,
        value: NextDialogues[key as keyof typeof NextDialogues]
      })
    }
  }

  return array.sort((a, b) => a.label.localeCompare(b.label))
}

interface Props {
  ActionButton?: React.ReactChild | React.ReactChild[]
  children?: React.ReactChild | React.ReactChild[]
  onClose?: (value: boolean) => void
  open?: boolean | undefined
  title?: string
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
}

function ActionEditor(props: Props): JSX.Element {
  const { ActionButton, title, maxWidth = "md" } = props
  const flowStore = useFlowStore()
  const nodeEditorStore = useNodeEditorStore()
  const nodeId = nodeEditorStore.nodeId
  const { actionEditorState } = nodeEditorStore
  const { action, nextDialogue } = actionEditorState
  const nextDialogues = createLabelValueArray()

  useEffect(() => {
    const currentNode = flowStore.nodes.find(node => node.id === nodeId)

    if (currentNode?.settings && Object.keys(currentNode.settings).length) {
      nodeEditorStore.updateState(EditorType.ACTION, {
        action: currentNode.settings.action,
        actionStateKey: currentNode.settings.actionStateKey,
        actionStateValueType: currentNode.settings.actionStateValueType,
        actionStateValue: currentNode.settings.actionStateValue,
        nextDialogue: currentNode.settings.nextDialogue,
        setIAPTid: currentNode.settings.setIAPTid,
        setIAPTName: currentNode.settings.setIAPTName,
        actionUpdateReferralWithKeys: currentNode.settings.actionUpdateReferralWithKeys ?? [],
        actionAddClinicalNotes: currentNode.settings.actionAddClinicalNotes ?? [],
        actionAddClinicalNotesShouldUpdateReferral:
          currentNode.settings.actionAddClinicalNotesShouldUpdateReferral ?? true,
        actionSetRiskLevel: currentNode.settings.actionSetRiskLevel,
        actionSetRiskLevelReason: currentNode.settings.actionSetRiskLevelReason,
        actionSetRiskLevelIsCrisis: currentNode.settings.actionSetRiskLevelIsCrisis,
        actionLanguageToSet: currentNode.settings.actionLanguageToSet ?? "browserLanguage"
      })
    } else {
      nodeEditorStore.resetState(EditorType.ACTION)
    }
  }, [flowStore.actionEditorNodeID, flowStore.nodes, flowStore.edges, nodeEditorStore, nodeId])

  const handleClose = (): void => {
    nodeEditorStore.setEditorClosed(EditorType.ACTION)
    nodeEditorStore.resetState(EditorType.ACTION)
  }

  const handleSave = (): void => {
    const nodeId = nodeEditorStore.nodeId
    const label = actionMap[action]

    const updatedState = actionEditorState

    flowStore.updateActionNode(nodeId, updatedState, label)
  }

  const handleActionChange = (value: string): void => {
    updateState({
      action: value,
      nextDialogue: undefined,
      actionStateKey: "",
      actionStateValue: "",
      actionStateValueType: "boolean",
      actionUpdateReferralWithKeys: [],
      actionAddClinicalNotes: [],
      actionAddClinicalNotesShouldUpdateReferral: true
    })
  }

  const handleDialogueChange = (event: SelectChangeEvent<DiscussionSteps>): void => {
    const value = event.target.value
    updateState({ nextDialogue: value })
  }

  const updateState = data => {
    nodeEditorStore.updateState(EditorType.ACTION, data)
  }

  return (
    <MuiDialog
      fullWidth
      maxWidth={maxWidth}
      open={nodeEditorStore.actionEditorOpen}
      onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={styles.nodeEditorContainer}>
          <div className={styles.optionsContainer}>
            <Typography>Options</Typography>
            <div className={styles.optionsContent}>
              <div className={styles.formControl}>
                <FormControl className={styles.formControl} fullWidth>
                  <Autocomplete
                    className={styles.autocompleteOptions}
                    multiple={false}
                    id="selected-action"
                    onChange={(_event, value) => handleActionChange(value!)}
                    options={Object.values(Actions)}
                    getOptionLabel={action => actionMap[action]}
                    value={action}
                    filterSelectedOptions
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Action"
                        placeholder="Please select an action that needs to be executed"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          {action === Actions.NEXT_DIALOGUE && (
            <div className={styles.nextDialogueContainer}>
              <FormControl className={styles.formControlNextDialogue}>
                <InputLabel id="next-dialogue-label">Next Dialogue</InputLabel>
                <Select
                  labelId="next-dialogue-label"
                  id="next-dialogue-select"
                  value={nextDialogue ?? ""}
                  label="Next Dialogue"
                  onChange={handleDialogueChange}>
                  {nextDialogues.map(nextDialogue => (
                    <MenuItem key={nextDialogue.value} value={nextDialogue.value}>
                      {nextDialogue.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {action === Actions.SET_STATE && <SetStateKey />}
          {action === Actions.SET_IAPT && <SetIAPT />}
          {action === Actions.SET_LANGUAGE && <SetLanguage />}
          {action === Actions.UPDATE_REFERRAL && <UpdateReferral />}
          {action === Actions.ADD_CLINICAL_NOTES && <AddClinicalNote />}
          {action === Actions.SET_RISK_LEVEL && <SetRiskLevel />}
        </div>
      </DialogContent>
      <DialogActions>
        {ActionButton && ActionButton}
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default observer(ActionEditor)
