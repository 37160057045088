import React, { MouseEvent, useCallback, useState } from "react"
import { observer } from "mobx-react"
import CustomizedAccordion from "../../../../../../components/Accordion"
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Button,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Typography
} from "@mui/material"
import { useServiceConfigurationStore } from "../../../../../../context/rootStoreContext"
import AddIcon from "@mui/icons-material/AddCircle"
import InfoIcon from "@mui/icons-material/Info"
import { CCGCodes, IIAPTDashboardService } from "@limbic/types"
import CancelIcon from "@mui/icons-material/Cancel"
import { validateGPCodes } from "../../../../../../../utils/iaptFormValidation"
import CCGExplainer from "./components/CCGExplainer"
import styles from "./IAPTConfigurator.module.scss"

type IAPTKeys = keyof IIAPTDashboardService

interface Props {
  saveDisabled: (value: boolean) => void
  setError?: (value: boolean) => void
}

function IAPTConfigurator(props: Props): JSX.Element {
  const [openPanel, setOpenPanel] = useState<string>("panel0")
  const [isCCGModalOpen, setIsCCGModalOpen] = useState<Record<number, boolean>>({ 0: false })
  const [hasDuplicateIAPTNameError, setHasDuplicateIAPTNameError] = useState<
    Record<number, boolean>
  >({
    0: false
  })
  const [gpCodesError, setGPCodesError] = useState<Record<number, boolean>>({
    0: false
  })
  const [ageThresholdError, setAgeThresholdError] = useState<Record<number, boolean>>({ 0: false })
  const [ageThresholdForTreatmentsError, setAgeThresholdForTreatmentsError] = useState<
    Record<number, boolean>
  >({ 0: false })

  const serviceConfigurationStore = useServiceConfigurationStore()

  const checkMissingOrEmpty = (iapt: IIAPTDashboardService): boolean => {
    if (!iapt.name || !iapt.backendInstanceID) return true
    if (!iapt.ccgs?.length && !iapt.gpCodes) return true
    if (!Number(iapt.ageThreshold)) return true
    if (iapt.ageThresholdForTreatments && !Number(iapt.ageThresholdForTreatments)) return true
    return false
  }

  const checkIAPTSForErrors = useCallback(
    (input: string, index: number, value: string): boolean => {
      const nameKeys: Partial<IAPTKeys>[] = ["name"]
      const ageThresholdKeys: Partial<IAPTKeys>[] = ["ageThreshold"]
      const ageThresholdForTreatmentsKeys: Partial<IAPTKeys>[] = ["ageThresholdForTreatments"]
      const gpKeys: Partial<IAPTKeys>[] = ["gpCodes"]
      if (gpKeys.includes(input as IAPTKeys)) {
        const isValid = validateGPCodes(value)
        setGPCodesError({ ...gpCodesError, [index]: !isValid })
        props.saveDisabled(!isValid)
        if (!isValid) return true
      }
      if (ageThresholdKeys.includes(input as IAPTKeys)) {
        const isValid = !!Number(value)
        setAgeThresholdError({ ...ageThresholdError, [index]: !isValid })
      }
      if (ageThresholdForTreatmentsKeys.includes(input as IAPTKeys)) {
        const isValid = !!Number(value)
        setAgeThresholdForTreatmentsError({ ...ageThresholdForTreatmentsError, [index]: !isValid })
      }
      const iapts = serviceConfigurationStore.eligibility.iapts

      if (nameKeys.includes(input as IAPTKeys)) {
        const hasDuplicateName = !!iapts
          ?.filter((_, i) => i !== index)
          .find(iapt => iapt.name === value)
        setHasDuplicateIAPTNameError({ ...hasDuplicateIAPTNameError, [index]: hasDuplicateName })
        if (hasDuplicateName) return true
      }
      if (!iapts?.length) return true
      const hasInvalidObjects = iapts.some(checkMissingOrEmpty)
      if (hasInvalidObjects) return true
      return false
    },
    [
      serviceConfigurationStore.eligibility.iapts,
      props,
      gpCodesError,
      hasDuplicateIAPTNameError,
      ageThresholdError,
      ageThresholdForTreatmentsError
    ]
  )

  const onInputChange = (input: string, index: number, value: string): void => {
    serviceConfigurationStore.setEligibilityConfigForIAPT({ [input]: value }, index)

    if (checkIAPTSForErrors(input, index, value)) {
      props.saveDisabled(true)
    } else {
      props.saveDisabled(false)
    }
  }

  const onAutoCompleteInputChange = (
    input: string,
    index: number,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<string> | undefined
  ): void => {
    if (reason === "selectOption") {
      const iapt = serviceConfigurationStore.eligibility.iapts![index]
      const currentCCGS = JSON.parse(JSON.stringify(iapt.ccgs ?? []))
      currentCCGS.push(details?.option)
      serviceConfigurationStore.setEligibilityConfigForIAPT({ [input]: currentCCGS }, index)
    } else {
      const iapt = serviceConfigurationStore.eligibility.iapts![index]
      const currentCCGS = JSON.parse(JSON.stringify(iapt.ccgs ?? []))
      const ccgIndexToRemove = currentCCGS.findIndex(ccgid => ccgid === details?.option)
      currentCCGS.splice(ccgIndexToRemove, 1)
      serviceConfigurationStore.setEligibilityConfigForIAPT({ [input]: currentCCGS }, index)
    }
  }

  const updateErrorState = (action: "add" | "remove", index: number): void => {
    if (action === "add") {
      setGPCodesError({
        ...gpCodesError,
        [index]: false
      })
      setHasDuplicateIAPTNameError({
        ...hasDuplicateIAPTNameError,
        [index]: false
      })
    } else {
      const currentGPCodesError = JSON.parse(JSON.stringify(gpCodesError))
      delete currentGPCodesError[index]
      setGPCodesError(currentGPCodesError)

      const currentHasDuplicateIAPTNameError = JSON.parse(JSON.stringify(hasDuplicateIAPTNameError))
      delete currentHasDuplicateIAPTNameError[index]
      setHasDuplicateIAPTNameError(currentHasDuplicateIAPTNameError)
    }
  }

  const addNewIAPT = (): void => {
    const newIndex = serviceConfigurationStore.eligibility.iapts?.length ?? 0
    updateErrorState("add", newIndex)
    serviceConfigurationStore.addIAPT()
  }

  const removeIAPTByIndex = (event: MouseEvent<HTMLButtonElement>, indexToRemove: number): void => {
    event.stopPropagation()
    updateErrorState("remove", indexToRemove)
    serviceConfigurationStore.removeIAPT(indexToRemove)
  }

  const getIAPTNameError = (hasDuplicateIAPTNameError, name?: string): string => {
    if (hasDuplicateIAPTNameError) return "IAPT Name already used"
    if (!name) return "IAPT Name is required"
    return ""
  }

  const getAgeThresholdHelperText = (
    ageThreshold: number | undefined,
    ageThresholdHasError: boolean
  ) => {
    if (!ageThreshold) return "Age Threshold is required"
    if (ageThreshold && ageThresholdHasError) return "Age Threshold should be a number"
    return ""
  }

  const toggleCCGInfoModal = (index: number) => {
    setIsCCGModalOpen({ ...isCCGModalOpen, [index]: !isCCGModalOpen[index] })
  }

  const addCCG = (key: string, index: number) => {
    const iapt = serviceConfigurationStore.eligibility.iapts![index]
    const currentCCGS = JSON.parse(JSON.stringify(iapt.ccgs ?? []))
    currentCCGS.push(key)
    serviceConfigurationStore.setEligibilityConfigForIAPT({ ccgs: currentCCGS }, index)
    toggleCCGInfoModal(index)
  }

  const getGpCodesErrorText = (
    gpCodesError: Record<number, boolean>,
    index: number,
    iapt?: IIAPTDashboardService
  ): string => {
    if (gpCodesError[index])
      return "Please check the input for syntax errors - it should be gp codes (i.e. A00001) separated by commas"
    if (!iapt?.gpCodes?.length && !iapt?.ccgs?.length)
      return "It seems you have not included any CCGs and GPs, either include GPs or CCGs (with GPs)"
    return ""
  }

  return (
    <div className={styles.iaptConfiguratorContainer}>
      {serviceConfigurationStore.eligibility.iapts?.map((iapt, index) => {
        return (
          <React.Fragment key={`iapt_${index}`}>
            <CCGExplainer
              index={index}
              onCCGClick={addCCG}
              isOpen={isCCGModalOpen[index]}
              onClose={() => toggleCCGInfoModal(index)}
            />
            <CustomizedAccordion
              forceBorderBottom
              key={`iapt${index}`}
              expanded={openPanel === `panel${index}`}
              maxHeight="500px"
              summary={iapt.name}
              summaryButton={
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<CancelIcon data-testid="delete-iapt-button" />}
                  disabled={serviceConfigurationStore.eligibility.iapts?.length === 1}
                  onClick={event => removeIAPTByIndex(event, index)}>
                  Remove
                </Button>
              }
              onPanelChange={(_, expanded) => setOpenPanel(expanded ? `panel${index}` : "")}>
              <div className={styles.iaptFormContainer}>
                <Typography variant="h6">Basic configuration</Typography>
                <FormControl className={styles.inputContainer}>
                  <FormLabel htmlFor="IAPTName">Clinic Name*:</FormLabel>

                  <TextField
                    InputProps={{
                      rows: 1,
                      multiline: true,
                      inputComponent: "input"
                    }}
                    error={!iapt.name || hasDuplicateIAPTNameError[index]}
                    helperText={getIAPTNameError(hasDuplicateIAPTNameError[index], iapt.name)}
                    name="IAPTName"
                    value={iapt?.name ?? ""}
                    onChange={e => onInputChange("name", index, e.target.value)}
                    placeholder="Enter the name of the IAPT"
                  />
                </FormControl>

                <FormControl className={styles.inputContainer}>
                  <FormLabel htmlFor="BackendInstanceID">Backend Instance ID*:</FormLabel>

                  <TextField
                    InputProps={{
                      rows: 1,
                      multiline: true,
                      inputComponent: "input"
                    }}
                    error={!iapt.backendInstanceID}
                    helperText={!iapt.backendInstanceID ? "Backend Instance ID is required" : ""}
                    name="BackendInstanceID"
                    value={iapt?.backendInstanceID ?? ""}
                    onChange={e => onInputChange("backendInstanceID", index, e.target.value)}
                    placeholder="Enter backend's instance ID i.e. DEMO_MAIN"
                  />
                </FormControl>

                <FormControl className={styles.inputContainer}>
                  <FormLabel htmlFor="PhoneNumber">Phone Number:</FormLabel>

                  <TextField
                    InputProps={{
                      rows: 1,
                      multiline: true,
                      inputComponent: "input"
                    }}
                    name="PhoneNumber"
                    value={iapt?.phoneNumber ?? ""}
                    onChange={e => onInputChange("phoneNumber", index, e.target.value)}
                    placeholder="Enter clinics phone number"
                  />
                </FormControl>

                <FormControl className={styles.inputContainer}>
                  <FormLabel htmlFor="AgeThreshold">Age Threshold:</FormLabel>

                  <TextField
                    InputProps={{
                      rows: 1,
                      multiline: true,
                      inputComponent: "input"
                    }}
                    error={!iapt.ageThreshold || ageThresholdError[index]}
                    helperText={getAgeThresholdHelperText(
                      iapt.ageThreshold,
                      ageThresholdError[index]
                    )}
                    type="number"
                    name="AgeThreshold"
                    value={iapt?.ageThreshold ?? ""}
                    onChange={e => onInputChange("ageThreshold", index, e.target.value)}
                    placeholder="Enter clinics age threshold"
                  />
                </FormControl>

                <FormControl className={styles.inputContainer}>
                  <FormLabel htmlFor="AgeThresholdForTreatments">
                    Age Threshold for Treatments:
                  </FormLabel>

                  <TextField
                    InputProps={{
                      rows: 1,
                      multiline: true,
                      inputComponent: "input"
                    }}
                    type="number"
                    error={ageThresholdForTreatmentsError[index]}
                    helperText={
                      ageThresholdForTreatmentsError[index]
                        ? "Age Threshold For Treatments should be a number"
                        : ""
                    }
                    name="AgeThresholdForTreatments"
                    value={iapt?.ageThresholdForTreatments ?? ""}
                    onChange={e =>
                      onInputChange("ageThresholdForTreatments", index, e.target.value)
                    }
                    placeholder="Enter clinics age threshold for providing treatments"
                  />
                </FormControl>

                <div className={styles.ccgAutoCompleteInputContainer}>
                  <div>
                    <Autocomplete
                      className={styles.autocompleteOptionsCCG}
                      multiple
                      id="eligibility-ccg-ids"
                      onChange={(_event, _value, reason, details) => {
                        onAutoCompleteInputChange("ccgs", index, reason, details)
                      }}
                      options={Object.keys(CCGCodes).map(key => key)}
                      renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => {
                          const { key, ...tagProps } = getTagProps({ index })
                          return (
                            <Chip
                              key={key}
                              variant="outlined"
                              label={option}
                              {...tagProps}
                              className={styles.ccgChip}
                            />
                          )
                        })
                      }
                      value={iapt?.ccgs?.map(ccgId => ccgId)}
                      filterSelectedOptions
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="CCGs Assigned to IAPT"
                          placeholder="Add all the CCGs that are eligible for this IAPT"
                        />
                      )}
                    />
                    <IconButton
                      size="medium"
                      className={styles.ccgInfoButton}
                      onClick={() => toggleCCGInfoModal(index)}>
                      <InfoIcon />
                    </IconButton>
                  </div>
                  <Typography variant="caption" className={styles.ccgEmptyWarning}>
                    {!iapt.ccgs?.length &&
                      "You have not included any CCGs, please make sure that this was intentional and if it's intentional make sure that GPs are assigned to this IAPT"}
                  </Typography>
                </div>

                <FormControl className={styles.inputContainer}>
                  <FormLabel htmlFor="GPIDs">
                    General Practicioner (GP) IDs that are eligible for this IAPT (separated by
                    commas):
                  </FormLabel>

                  <TextField
                    InputProps={{
                      rows: 4,
                      multiline: true,
                      inputComponent: "textarea"
                    }}
                    name="GPIDs"
                    className={styles.textareaAutoSize}
                    maxRows={4}
                    value={iapt?.gpCodes ?? ""}
                    onChange={e => onInputChange("gpCodes", index, e.target.value)}
                    placeholder="Enter the IDs of specific GPs that are eligible for this IAPT (optional)"
                    error={gpCodesError[index] || (!iapt?.gpCodes?.length && !iapt.ccgs?.length)}
                    helperText={getGpCodesErrorText(gpCodesError, index, iapt)}
                  />
                </FormControl>
              </div>
            </CustomizedAccordion>
          </React.Fragment>
        )
      })}
      <div className={styles.addConditionButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<AddIcon />}
          className={styles.addButton}
          disabled={false}
          onClick={() => addNewIAPT()}>
          Add IAPT
        </Button>
      </div>
    </div>
  )
}

export default observer(IAPTConfigurator)
