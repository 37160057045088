import React, { ChangeEvent } from "react"
import { observer } from "mobx-react"
import MessageList from "../MessageList"
import { useNodeEditorStore } from "../../../../../../context/rootStoreContext"
import MessageForm from "../MessageForm/MessageForm"
import { FormControl, TextField, Typography } from "@mui/material"
import styles from "./AssessmentAndTreatmentsCustomQuestionnaires.module.scss"
import SolidDivider from "../../../../../SolidDivider"
import FormControlWithCheckBox from "../FormControlWithCheckBox"
import { AssessmentType, ChatFlowsEnum, QuestionnaireDialogue } from "@limbic/types"
import CustomQuestionnaires from "./CustomQuestionnaires/CustomQuestionaires"

function AssessmentAndTreatmentsCustomQuestionnaires(): JSX.Element {
  const nodeEditorStore = useNodeEditorStore()
  const { chatFlowEditorState } = nodeEditorStore
  const { chatFlowSettings } = chatFlowEditorState
  const messages = chatFlowSettings.messages
  const currentSettings = chatFlowSettings?.[ChatFlowsEnum.ASSESSMENT_CUSTOMISABLE_ADSM]

  const updateState = data => {
    nodeEditorStore.updateChatFlowState(data)
  }

  const handleCrisisNumbersChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.ASSESSMENT_AND_TREATMENTS]: {
          ...currentSettings,
          crisisNumbersShared: event.target.value
        }
      }
    })
  }

  const handleCustomQuestionnaires = (
    assessmentType: AssessmentType,
    questionnaire: QuestionnaireDialogue
  ) => {
    updateState({
      chatFlowSettings: {
        ...chatFlowSettings,
        [ChatFlowsEnum.ASSESSMENT_CUSTOMISABLE_ADSM]: {
          ...currentSettings,
          customQuestionnaires: {
            ...(currentSettings?.customQuestionnaires ?? {}),
            [assessmentType]: questionnaire
          }
        }
      }
    })
  }

  return (
    <>
      <CustomQuestionnaires handleCustomQuestionnaire={handleCustomQuestionnaires} />
      <SolidDivider />
      <FormControlWithCheckBox
        name="enableADSM"
        label="Enable ADSM Triage"
        labelPlacement="start"
        updateState={updateState}
      />
      <SolidDivider />
      <Typography
        align="right"
        className={styles.assessmentAndTreatmentsCustomQuestionnairesTitle}
        variant="subtitle1">
        Ask user if they can keep themselves safe:
      </Typography>
      <MessageList
        messages={messages?.askCanYouKeepYourselfSafe ?? []}
        messageType="askCanYouKeepYourselfSafe"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.askCanYouKeepYourselfSafe}
        placeholder="Type a message here..."
        name="askCanYouKeepYourselfSafe"
        updateState={updateState}
        messages={messages?.askCanYouKeepYourselfSafe ?? []}
      />
      <SolidDivider />
      <Typography
        align="right"
        className={styles.assessmentAndTreatmentsCustomQuestionnairesTitle}
        variant="subtitle1">
        Say crisis messages, when user answers No to "Can you keep yourself safe" question
      </Typography>
      <MessageList
        messages={messages?.sayCrisisRiskPathway ?? []}
        messageType="sayCrisisRiskPathway"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayCrisisRiskPathway}
        placeholder="Type a message here..."
        name="sayCrisisRiskPathway"
        updateState={updateState}
        messages={messages?.sayCrisisRiskPathway ?? []}
      />
      <SolidDivider />
      <Typography
        align="right"
        className={styles.assessmentAndTreatmentsCustomQuestionnairesTitle}
        variant="subtitle1">
        Share specific crisis numbers with the user
      </Typography>
      <MessageList
        messages={messages?.sayCrisisNumbersRiskPathway ?? []}
        messageType="sayCrisisNumbersRiskPathway"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayCrisisNumbersRiskPathway}
        placeholder="Type a message here..."
        name="sayCrisisNumbersRiskPathway"
        updateState={updateState}
        messages={messages?.sayCrisisNumbersRiskPathway ?? []}
      />
      <SolidDivider />
      <Typography
        align="right"
        className={styles.assessmentAndTreatmentsCustomQuestionnairesTitle}
        variant="subtitle1">
        Closing message (let's carry on) before bot moves to additional questionnaires
      </Typography>
      <MessageList
        messages={messages?.sayLetsCarryOn ?? []}
        messageType="sayLetsCarryOn"
        chatFlowSettings={chatFlowSettings}
        updateState={updateState}
      />
      <MessageForm
        value={chatFlowSettings.currentMessage?.sayLetsCarryOn}
        placeholder="Type a message here..."
        name="sayLetsCarryOn"
        updateState={updateState}
        messages={messages?.sayLetsCarryOn ?? []}
      />
      <SolidDivider />
      <div className={styles.crisisNumbersSharedContainer}>
        <Typography>Summary of numbers that were shared for tracking purposes</Typography>
        <FormControl style={{ width: "100%", marginTop: 16 }}>
          <TextField
            className={styles.crisisNumbersShared}
            id="retry-postcode-times-select"
            value={chatFlowSettings.assessmentAndTreatments?.crisisNumbersShared ?? "NHS111, 999"}
            label="Crisis numbers shared"
            onChange={event => handleCrisisNumbersChange(event)}
          />
        </FormControl>
      </div>
    </>
  )
}

export default observer(AssessmentAndTreatmentsCustomQuestionnaires)
