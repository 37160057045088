import React, { useEffect } from "react"
import {
  Autocomplete,
  FormControlLabel,
  Dialog as MuiDialog,
  TextField,
  Typography
} from "@mui/material/"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Button from "../../../Button"
import { useFlowStore, useNodeEditorStore } from "../../../../context/rootStoreContext"
import { EditorType } from "../../models/NodeEditors"
import { observer } from "mobx-react"
import { Checkbox, FormControl } from "@mui/material"
import styles from "./IneligibleUserEditor.module.scss"
import { IneligibilityReason } from "@limbic/types"
import { INELIGIBILITY_REASON_PLACEHOLDER } from "../../../../stores/NodeEditorStore"

interface Props {
  ActionButton?: React.ReactChild | React.ReactChild[]
  children?: React.ReactChild | React.ReactChild[]
  onClose?: (value: boolean) => void
  open?: boolean | undefined
  title?: string
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
}

function IneligibleUserEditor(props: Props): JSX.Element {
  const flowStore = useFlowStore()
  const nodeEditorStore = useNodeEditorStore()
  const { ineligibleUserEditorState } = nodeEditorStore
  const [isReasonSet, setIsReasonSet] = React.useState(
    ineligibleUserEditorState.reason !== INELIGIBILITY_REASON_PLACEHOLDER
  )

  const { ActionButton, title, maxWidth = "md" } = props

  useEffect(() => {
    const nodeId = nodeEditorStore.nodeId
    const currentNode = flowStore.nodes.find(node => node.id === nodeId)

    if (Object.keys(currentNode?.settings?.ineligibleUser ?? {}).length) {
      nodeEditorStore.updateState(EditorType.INELIGIBLE_USER, currentNode?.settings?.ineligibleUser)
    } else {
      nodeEditorStore.resetState(EditorType.INELIGIBLE_USER)
    }
  }, [flowStore.nodes, nodeEditorStore])

  const handleClose = (): void => {
    nodeEditorStore.setEditorClosed(EditorType.INELIGIBLE_USER)
    nodeEditorStore.resetState(EditorType.INELIGIBLE_USER)
  }

  const handleSave = (): void => {
    const nodeId = nodeEditorStore.nodeId
    const updatedState = ineligibleUserEditorState
    flowStore.updateIneligibleUserNode(nodeId, updatedState)
  }

  const handleReasonChange = (value: string) => {
    setIsReasonSet(value !== INELIGIBILITY_REASON_PLACEHOLDER)
    updateState({
      ...ineligibleUserEditorState,
      reason: value
    })
  }

  const handleTrackInDatabaseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({
      ...ineligibleUserEditorState,
      shouldUpdateDatabase: event.target.checked
    })
  }

  const handleTrackInMixpanelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({
      ...ineligibleUserEditorState,
      shouldTrackMixpanel: event.target.checked
    })
  }

  const updateState = data => {
    nodeEditorStore.updateState(EditorType.INELIGIBLE_USER, data)
  }

  return (
    <MuiDialog
      fullWidth
      maxWidth={maxWidth}
      open={nodeEditorStore.ineligibleUserEditorOpen}
      onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={styles.nodeEditorContainer}>
          <Typography>Options</Typography>
          <div className={styles.optionsContainer}>
            <FormControl fullWidth>
              <Autocomplete
                freeSolo
                multiple={false}
                id="ineligibility-reason"
                onChange={(_event, value) => handleReasonChange(value!)}
                options={Object.values(IneligibilityReason)}
                value={ineligibleUserEditorState.reason}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    error={!isReasonSet}
                    onChange={event => handleReasonChange(event.target.value)}
                    variant="standard"
                    label="Ineligibility Reason"
                    placeholder="Type an ineligibility reason"
                  />
                )}
              />
              <Typography variant="caption" className={styles.ineligibilityReasonCaption}>
                Please try to use the predefined list
                <br />
                If an option is not available, you can type a custom one but please be short and
                precise
              </Typography>
            </FormControl>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                labelPlacement="end"
                label="Track in database"
                control={
                  <Checkbox
                    checked={ineligibleUserEditorState.shouldUpdateDatabase}
                    onChange={handleTrackInDatabaseChange}
                  />
                }
              />
              <FormControlLabel
                labelPlacement="end"
                label="Track in mixpanel"
                control={
                  <Checkbox
                    checked={ineligibleUserEditorState.shouldTrackMixpanel}
                    onChange={handleTrackInMixpanelChange}
                  />
                }
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        {ActionButton && ActionButton}
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSave} disabled={!isReasonSet}>
          Save
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default observer(IneligibleUserEditor)
