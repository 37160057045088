import React, { MouseEvent, useState } from "react"
import { NodeProps, Position } from "react-flow-renderer"
import classNames from "classnames"
import { styled } from "@stitches/react"
import { CustomNode } from "../CustomNode"
import RhombusShape from "../../../components/shapes/Rhombus"
import NodeSettings from "../NodeSettings"
import useDeleteNode from "../../../../../hooks/useDeleteNode"
import { useNodeEditorStore } from "../../../../../context/rootStoreContext"
import { EditorType } from "../../../models/NodeEditors"

const sourceStyle = ({ position, style }) => ({ ...style, ...styleMap[position] })

export default function BasicConditionNode(props: NodeProps) {
  const { id, data, selected, dragging } = props
  const nodeEditorStore = useNodeEditorStore()
  // eslint-disable-next-line
  const [message, setMessage] = useState(data?.label ?? "")
  const onDelete = useDeleteNode(id)

  const onDoubleClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    nodeEditorStore.setEditorOpen(EditorType.CONDITION, id)
  }

  return (
    <CustomNode.Container
      dynamicHandles
      id={id}
      dragging={dragging}
      sourceHandleStyle={sourceStyle}
      targetHandleStyle={sourceStyle}>
      <Container
        className={classNames("react-flow__node-shape nopan", { selected, nodrag: false })}>
        <CustomNode.NodeId conditionNode>{id}</CustomNode.NodeId>
        <RhombusShape fillColor={"#E1D5E7"} strokeWidth={selected ? 1.5 : 1} />
        <AbsoluteContainer>
          <CustomNode.LabelEllipsis>{data.label}</CustomNode.LabelEllipsis>
        </AbsoluteContainer>
      </Container>
      <NodeSettings open={props.selected && !props.dragging} onDelete={onDelete} />
      <DoubleClickOverlay onDoubleClick={onDoubleClick} />
    </CustomNode.Container>
  )
}

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center"
})

const AbsoluteContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: "100%",
  height: "100%"
})

const DoubleClickOverlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

const rootHandleStyle = {
  height: "12px",
  width: "12px",
  borderColor: "black"
}

const leftHandleStyle = {
  ...rootHandleStyle,
  left: "-8px"
}

const rightHandleStyle = {
  ...rootHandleStyle,
  right: "-6px"
}

const topHandleStyle = {
  ...rootHandleStyle,
  top: "-8px"
}

const bottomHandleStyle = {
  ...rootHandleStyle,
  bottom: "-6px"
}

const styleMap = {
  [Position.Left]: leftHandleStyle,
  [Position.Right]: rightHandleStyle,
  [Position.Top]: topHandleStyle,
  [Position.Bottom]: bottomHandleStyle
}
