import { action, makeObservable, observable } from "mobx"
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge as RF_Edge,
  EdgeChange,
  getRectOfNodes,
  Node as RF_Node,
  NodeChange,
  Position,
  ReactFlowInstance,
  XYPosition
} from "react-flow-renderer"
import Vector2 from "../../models/Vector2"
import toCamelCase from "../../utils/toCamelCase"
import formatDashboardMessages from "../../utils/formatDashboardMessages"
import {
  ChatFlowsEnum,
  DiscussionSteps,
  DiscussionsStepsItem,
  DiscussionsStepsList,
  IAdvancedCondition,
  IDashboardEdge,
  IDashboardNode,
  IDashboardNodeSettings,
  IDefaultChatFlowSettings,
  IIneligibleUserSettings,
  KeyType,
  NodeTypes
} from "@limbic/types"
import optionsMap from "../components/FlowEditor/components/ChatFlowEditor/constants/options"
import deepMerge from "../../utils/deepMerge"
import { DropResult } from "@hello-pangea/dnd"
import _isEqual from "lodash.isequal"
import { IFlowValidation } from "../components/FlowEditor/models/NodeEditors"
import validateFlow from "../../utils/validateFlow"

export const defaultOtherDialoguesList = [
  {
    value: DiscussionSteps.Crisis,
    body: "Crisis",
    configurable: true,
    disabled: false,
    required: true,
    fixed: true
  }
]

export const defaultDiscussionsStepsList: DiscussionsStepsList = [
  {
    value: DiscussionSteps.Intro,
    disabled: false,
    fixed: true,
    required: true,
    body: "Introduction",
    configurable: true
  },
  {
    value: DiscussionSteps.Permissions,
    disabled: false,
    fixed: false,
    required: false,
    body: "Permissions",
    configurable: true
  },
  {
    value: DiscussionSteps.GetName,
    disabled: false,
    required: false,
    fixed: false,
    body: "Get Name",
    configurable: true
  },
  {
    value: DiscussionSteps.Eligibility,
    disabled: false,
    required: false,
    fixed: false,
    body: "Eligibility",
    configurable: true
  },
  {
    value: DiscussionSteps.SelfReferral,
    disabled: false,
    required: false,
    fixed: false,
    body: "Self Referral",
    configurable: true
  },
  {
    value: DiscussionSteps.Assessment,
    disabled: false,
    required: false,
    fixed: false,
    body: "Assessment",
    configurable: true
  },
  {
    value: DiscussionSteps.TreatmentOptions,
    disabled: true,
    required: true,
    fixed: false,
    body: "Treatments",
    configurable: false
  },
  {
    value: DiscussionSteps.BookAppointment,
    disabled: true,
    required: false,
    fixed: false,
    body: "Book Appointment",
    configurable: true
  },
  {
    value: DiscussionSteps.Goodbye,
    disabled: false,
    required: true,
    fixed: true,
    body: "Goodbye",
    configurable: true
  }
]

export class FlowStore {
  newNodeID: number
  flowInstance?: ReactFlowInstance
  @observable shouldRecenter?: boolean
  @observable currentHighLevelDialogue: DiscussionsStepsItem
  @observable highLevelFlow: DiscussionsStepsList
  highLevelFlowUpdated: DiscussionsStepsList | undefined
  highLevelFlowHasUpdates?: boolean
  @observable questionEditorNodeID?: string
  @observable conditionEditorNodeID?: string
  @observable actionEditorNodeID?: string
  @observable chatFlowEditorNodeID?: string
  @observable diagramValidity: Partial<
    Record<DiscussionSteps, { isValid: boolean; validation: IFlowValidation }>
  >
  @observable title: string
  @observable description?: string
  @observable nodes: IDashboardNode[]
  @observable edges: IDashboardEdge[]

  constructor() {
    Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach(method => {
      if (method !== "constructor" && typeof this[method] === "function") {
        this[method] = this[method].bind(this)
      }
    })
    this.newNodeID = 0
    this.title = "Untitled Flow"
    this.nodes = []
    this.edges = []
    this.currentHighLevelDialogue = defaultDiscussionsStepsList.find(
      item => item.value === DiscussionSteps.SelfReferral
    )!
    this.highLevelFlow = defaultDiscussionsStepsList
    this.highLevelFlowUpdated = undefined
    this.highLevelFlowHasUpdates = false
    this.diagramValidity = {}
    this.questionEditorNodeID = ""
    this.conditionEditorNodeID = ""
    this.actionEditorNodeID = ""
    this.chatFlowEditorNodeID = ""
    this.shouldRecenter = false
    makeObservable(this)
  }

  setFlowInstance(instance?: ReactFlowInstance): void {
    this.flowInstance = instance
  }

  /** Actions */

  @action
  setTitle(title?: string): void {
    this.title = title ?? "Untitled Flow"
  }

  @action
  setDescription(description?: string): void {
    this.description = description
  }

  @action
  setNodes(nodes: IDashboardNode[]): void {
    this.nodes = nodes
  }

  @action
  setEdges(edges: IDashboardEdge[]): void {
    this.edges = edges
  }

  @action
  applyNodeChanges(changes: NodeChange[]): void {
    this.setNodes(applyNodeChanges(changes, this.nodes as RF_Node[]) as IDashboardNode[])
    this.setDiagramValidity()
  }

  @action
  applyEdgeChanges(changes: EdgeChange[]): void {
    this.setEdges(applyEdgeChanges(changes, this.edges as RF_Edge[]) as IDashboardEdge[])
    this.setDiagramValidity()
  }

  @action
  addEdge(connection: Connection): void {
    this.setEdges(addEdge(connection, this.edges as RF_Edge[]) as IDashboardEdge[])
    this.setDiagramValidity()
  }

  @action
  updateEdgeWithOption(id, value, source): void {
    const edgeIndex = this.edges.findIndex(edge => edge.id.includes(id))
    this.edges[edgeIndex] = {
      ...this.edges[edgeIndex],
      source,
      sourceHandle: "s-bottom",
      data: { label: value }
    }
  }

  @action
  addNode(type: NodeTypes, data?: { label?: string; position?: Vector2 }): IDashboardNode {
    const { label, position = this.getNewNodePosition() } = data ?? {}
    const prependStart = !this.nodes.length && type !== NodeTypes.FlowStart
    const startNode = prependStart
      ? this.addStartingNode({ x: position.x, y: position.y - 100 })
      : undefined

    const id = this.getNewNodeID()

    const newNode: IDashboardNode = { id, type, data: { label }, position }
    this.setNodes([...this.nodes, newNode])

    if (startNode) {
      this.addEdge({
        source: startNode.id,
        target: id,
        sourceHandle: null,
        targetHandle: `t-${Position.Top}`
      })
    }

    return newNode
  }

  @action
  duplicateNode(node: IDashboardNode): void {
    const newNodeID = this.getNewNodeID()

    const newNode = JSON.parse(
      JSON.stringify({
        ...node,
        id: newNodeID,
        data: {
          ...node.data,
          nodeId: newNodeID
        },
        selected: true,
        position: {
          x: node.position.x + 50,
          y: node.position.y + 50
        }
      })
    )

    if (node.type === NodeTypes.Question) {
      const newQuestionName = node.settings?.questionName
        ? `[id${newNodeID}] duplicate of ${node.settings?.questionName}`
        : `[id${newNodeID}] duplicate of ${node.id}`
      const newQuestionID = toCamelCase(newQuestionName)

      newNode.settings = {
        ...newNode.settings,
        questionName: newQuestionName,
        questionID: newQuestionID
      }
    }

    const oldNode = {
      ...node,
      selected: false
    }

    this.setNodes([...this.nodes.filter(n => n.id !== node.id), oldNode, newNode])
  }

  getNode(id: string): IDashboardNode | undefined {
    return this.nodes.find(node => node.id === id)
  }

  @action
  updateQuestionNode(nodeId: string, data: IDashboardNodeSettings): void {
    const nodeIndex = this.nodes.findIndex(node => node.id === nodeId)
    const questionID = toCamelCase(data.questionName ?? nodeId)
    const promptType = data.promptType

    const isCrisisPrompt = ["text", "inlinePicker"].includes(promptType ?? "")
    const isCrisisShouldRepeatQuestionUndefined =
      typeof this.nodes[nodeIndex].settings?.promptSettings?.crisisShouldRepeatQuestion ===
      "undefined"
    const shouldSetCrisisShouldRepeatQuestion =
      isCrisisPrompt && isCrisisShouldRepeatQuestionUndefined

    const dataCopy = JSON.parse(JSON.stringify(data))

    const updatedData = {
      ...dataCopy,
      promptSettings: {
        ...dataCopy.promptSettings,
        ...(shouldSetCrisisShouldRepeatQuestion && { crisisShouldRepeatQuestion: false })
      }
    }

    this.nodes[nodeIndex] = {
      ...this.nodes[nodeIndex],
      data: {
        label: data.messages,
        choices: data.promptSettings?.options
          ? data.promptSettings.options.map(option => ({ body: option, value: option }))
          : [],
        checkboxOptions: data.promptSettings?.checkboxOptions ?? [],
        nodeId: this.nodes[nodeIndex].id,
        type: data.promptType
      },
      settings: {
        ...updatedData,
        questionID
      }
    }
  }

  @action
  updateConditionNode(nodeId, data, label): void {
    const nodeIndex = this.nodes.findIndex(node => node.id === nodeId)
    this.nodes[nodeIndex] = {
      ...this.nodes[nodeIndex],
      data: {
        label,
        nodeId
      },
      settings: {
        ...data
      }
    }
  }

  @action
  updateAdvancedConditionNode(
    nodeId: string,
    data: IAdvancedCondition,
    label: string,
    customInput?: string
  ): void {
    let updatedData = JSON.parse(JSON.stringify(data))
    if (customInput?.length) {
      updatedData = {
        ...data,
        leftOperand: {
          ...data.leftOperand,
          value: {
            ...data.leftOperand.value,
            sourceKey: customInput,
            type: KeyType.Unknown,
            context: "customState"
          }
        }
      }
    }

    const nodeIndex = this.nodes.findIndex(node => node.id === nodeId)
    this.nodes[nodeIndex] = {
      ...this.nodes[nodeIndex],
      data: {
        label,
        nodeId
      },
      settings: {
        ...this.nodes[nodeIndex]?.settings,
        advancedCondition: updatedData
      }
    }
  }

  @action
  updateActionNode(nodeId, data, label): void {
    const nodeIndex = this.nodes.findIndex(node => node.id === nodeId)
    this.nodes[nodeIndex] = {
      ...this.nodes[nodeIndex],
      data: {
        label,
        nodeId
      },
      settings: {
        ...data
      }
    }
  }

  @action
  updateIneligibleUserNode(nodeId: string, data: IIneligibleUserSettings): void {
    const nodeIndex = this.nodes.findIndex(node => node.id === nodeId)
    this.nodes[nodeIndex] = {
      ...this.nodes[nodeIndex],
      data: {
        ...this.nodes[nodeIndex].data,
        nodeId
      },
      settings: {
        ineligibleUser: data
      }
    }
  }

  @action
  updateChatFlowNode(
    nodeId: string,
    /**
     * 👇 This needs to be
     * data: { chatFlow: string, chatFlowSettings: IDefaultChatFlowSettings }
     * but it's causing a mismatch with the node below - need to make an update in
     * the limbic/types and make optional the properties promptType and promptSettings
     * which will probably break a lot of things... keeping 'any' for now
     */
    data: { chatFlow: ChatFlowsEnum; chatFlowSettings: IDefaultChatFlowSettings },
    label: string,
    chatFlow?: ChatFlowsEnum
  ): void {
    const nodeIndex = this.nodes.findIndex(node => node.id === nodeId)
    let filteredData = data

    if (chatFlow) {
      /** Cleanup all chatflows that are not being saved
       * Possibility for a better solution for this
       * but this is what I came up with for now
       */
      const allowedKeys = new Set([
        chatFlow,
        "choices", // Possibly not needed
        "choicesMap", // Possibly not needed
        "currentMessage",
        "messages",
        "options", // Possibly not needed
        "optionsToSelectIndividually"
      ])

      if (data.chatFlowSettings[chatFlow]?.choicesMap) {
        filteredData = {
          ...data,
          chatFlowSettings: Object.entries(data.chatFlowSettings).reduce((result, [key, value]) => {
            if (value && allowedKeys.has(key)) result[key] = value
            return result
          }, {})
        }
      }

      filteredData.chatFlowSettings.messages = formatDashboardMessages(
        chatFlow,
        filteredData.chatFlowSettings.messages
      )
    }

    const hasChoicesMap = !!filteredData.chatFlowSettings[chatFlow!]?.choicesMap?.length
    const hasOptionsMap = !!optionsMap[chatFlow!]?.length

    if (!hasChoicesMap && hasOptionsMap) {
      const options = optionsMap[chatFlow!]
      filteredData = deepMerge(filteredData, {
        chatFlowSettings: { [chatFlow!]: { choicesMap: options } }
      })
    }

    if (
      !filteredData.chatFlowSettings[chatFlow!]?.secondaryChoicesMap?.length &&
      optionsMap[`${chatFlow}Secondary`!]?.length
    ) {
      const options = optionsMap[`${chatFlow}Secondary`!]
      filteredData = deepMerge(filteredData, {
        chatFlowSettings: { [chatFlow!]: { secondaryChoicesMap: options } }
      })
    }

    this.nodes[nodeIndex] = {
      ...this.nodes[nodeIndex],
      data: { label, chatFlow, nodeId },
      settings: { ...filteredData }
    }
  }

  @action
  addStartingNode(position: XYPosition): IDashboardNode {
    this.newNodeID += 1
    const id = `${this.newNodeID}`
    const startNode: IDashboardNode = { id, type: NodeTypes.FlowStart, data: {}, position }
    this.setNodes([...this.nodes, startNode])
    return startNode
  }

  @action
  deleteNodes(nodes: RF_Node[]): void {
    const ids = nodes.map(n => n.id)
    this.setNodes(this.nodes.filter(n => !ids.includes(n.id)))
    this.setDiagramValidity()
  }

  @action
  deleteEdges(edges: RF_Edge[]): void {
    const ids = edges.map(n => n.id)
    this.setEdges(this.edges.filter(n => !ids.includes(n.id)))
    this.setDiagramValidity()
  }

  @action
  initHighLevelFlow(fetchedStepsOrder: DiscussionsStepsList | undefined | null): void {
    if (this.highLevelFlowUpdated) {
      this.highLevelFlow = this.highLevelFlowUpdated
    } else if (!fetchedStepsOrder) {
      this.highLevelFlow = defaultDiscussionsStepsList
    } else if (fetchedStepsOrder.length && !_isEqual(fetchedStepsOrder, this.highLevelFlow)) {
      this.highLevelFlow = fetchedStepsOrder
    }
  }

  @action
  updateCurrentHighLevelDialogue(dialogue: DiscussionSteps, type?: "other"): void {
    this.setShouldRecenter(true)
    if (type === "other") {
      this.currentHighLevelDialogue = defaultOtherDialoguesList.find(
        item => item.value === dialogue
      )!
    } else {
      this.currentHighLevelDialogue = defaultDiscussionsStepsList.find(
        item => item.value === dialogue
      )!
    }
  }

  @action
  reorderHighLevelFlow(droppedItem: DropResult): void {
    const updatedList = [...this.highLevelFlow]
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    if (droppedItem.destination)
      updatedList.splice(droppedItem.destination?.index, 0, reorderedItem)
    this.highLevelFlow = updatedList
  }

  @action
  setHighLevelFlow(flow: DiscussionsStepsList): void {
    this.highLevelFlowUpdated = flow
    this.highLevelFlowHasUpdates = true
  }

  @action
  toggleHighLevelFlowDialogue(id: string): void {
    this.highLevelFlow = this.highLevelFlow.map(item =>
      item.value === id ? { ...item, disabled: !item.disabled } : item
    )
  }

  @action
  setDiagramValidity(): void {
    const dialogueName = this.currentHighLevelDialogue.value
    const [isFlowValid, validation] = validateFlow(this.nodes, this.edges, dialogueName)

    this.diagramValidity = {
      ...this.diagramValidity,
      [dialogueName]: {
        isValid: isFlowValid,
        validation
      }
    }
  }

  @action
  getIsDiagramValid(): boolean {
    const dialogueName = this.currentHighLevelDialogue.value
    const isValid = this.diagramValidity[dialogueName]
      ? this.diagramValidity[dialogueName]?.isValid
      : true
    return isValid ?? true
  }

  @action
  setShouldRecenter(recenter: boolean): void {
    this.shouldRecenter = recenter
  }

  /** Generic Handlers */

  getNewNodePosition(): Vector2 {
    if (!this.nodes.length) return { x: 0, y: 0 }
    const rect = getRectOfNodes(this.nodes)
    return { x: rect.x + rect.width / 2, y: rect.y + rect.height + 60 }
  }

  centerLastNode(): void {
    const lastNode = this.nodes[this.nodes.length - 1]
    this.flowInstance?.setCenter(lastNode.position.x, lastNode.position.y, { duration: 400 })
  }

  getNewNodeID(): string {
    const maxID = Object.keys(this.nodes).length
      ? Math.max(...this.nodes.map(o => Number(o.id)))
      : 0
    this.newNodeID = maxID + 1
    return `${this.newNodeID}`
  }
}
